import { IUser, Settings } from '../interfaces/user.type';
import { createSlice } from "@reduxjs/toolkit";
import authService from "../services/auth.service";
import { RootState } from "./createStore";
import localStorageService from "../services/localStorage.service";
import gameService from '../services/game.service';
import { Language } from '../interfaces/language.type';
import { IRefferal } from '../interfaces/refferal.type';

const listLanguages: Language[] = [
  { id: "1", title: "English", locale: "en"  },
  { id: "2", title: "Russian", locale: "ru"  },
  { id: "3", title: "Georgian", locale: "ka"  },
  { id: "4", title: "German", locale: "de"  },
  { id: "5", title: "Catalan", locale: "ca"  },
  { id: "6", title: "Croatian", locale: "hr"  },
  { id: "7", title: "Dutch", locale: "nl"  },
  { id: "8", title: "French", locale: "fr"  },
  { id: "9", title: "Hebrew", locale: "he"  },
  { id: "10", title: "Italian", locale: "it"  },
  { id: "11", title: "Kazakh", locale: "kk"  },
  { id: "12", title: "Malay", locale: "ms"  },
  { id: "13", title: "Polish", locale: "pl"  },
  { id: "14", title: "Portuguese", locale: "pt" },
  { id: "15", title: "Serbian", locale: "sr" },
  { id: "16", title: "Spanish", locale: "es"  },
  { id: "17", title: "Turkish", locale: "tr"  },
  { id: "18", title: "Ukrainian", locale: "uk" },
  { id: "19", title: "Arabic", locale: "ar" },
  { id: "20", title: "Afrikaans", locale: "af" },
  { id: "21", title: "Chinese", locale: "zh" },
  { id: "22", title: "Hindi", locale: "hi" },
  { id: "23", title: "Japanese", locale: "ja" },
  { id: "24", title: "Kyrgyz", locale: "ky" },
  { id: "25", title: "Korean", locale: "ko" },
  { id: "26", title: "Tajik", locale: "tg" },
  { id: "27", title: "Uzbek", locale: "uz" },
  { id: "28", title: "Azerbaijani", locale: "az" },
  { id: "29", title: "Armenian", locale: "hy" }
];

// const mockUser: IUser = {
//   id: "1263216650",
//   username: "SnowSTorM27",
//   is_premium: false,
//   league_id: "junior_3",
//   experience: 1000000.335,
//   tap_energy_max: 350,
//   tap_strength: 1,
//   earning_per_hour: 3000,
//   friends_count: 0,
//   photo_url: null,
//   sparring_energy: 0,
//   sparring_allowed_at_ts: null,
//   // sparring_allowed_at_ts: 1726417950,
//   user_setting: {
//     locale: "ru",
//     vibration: false
//   },
//   user_cards: [
//
//   ],
//   user_boosters: [
//
//   ],
//   user_wallet: {
//     gold: 0,
//     silver: 10000
//   }
// };

// const mockRef: IRefferal[] = [
//   {
//       id: "380770878",
//       username: "webmikez",
//       is_premium: false,
//       experience: 565.4027777777779,
//       earning_per_hour: 2750,
//       photo_url: null,
//       reward_silver: 2
//   }
// ]


type SliceState = {
  isLoading: boolean,
  earned: {experience: number} | null,
  renewed: {
    tap_energy: number,
  }
  user: null | IUser,
  token: null | string,
  errorAuth: any,
  error: any,
  isLoggedIn: boolean,
  dataLoaded: boolean,
  firstStartUp: boolean,
  languages: Language[],
  dataRefferals : boolean,
  refferals: IRefferal[] | null,
  lastCurrentEnergy: null | SyncEnergy
}

type SyncEnergy = {
  time: number,
  energy: number
}

const initialState: SliceState = {
  isLoading: false,
  renewed: {
    tap_energy: 0,
  },
  user: null,
  earned: { experience: 0 },
  token: null,
  errorAuth: null,
  error: null,
  isLoggedIn: false,
  dataLoaded: false,
  firstStartUp: true,
  languages: listLanguages,
  refferals: [],
  dataRefferals: false,
  lastCurrentEnergy: null
};

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    authRequested: (state) => {
      state.isLoading = true;
      state.errorAuth = null;
    },
    authRequestSuccess: (state, action) => {
      state.user = action.payload?.user;
      state.token = action.payload?.token;
      state.earned = action.payload?.earned;
      state.renewed = action.payload.renewed;
      state.isLoading = false;
      state.isLoggedIn = true;
      state.dataLoaded = true;
      state.firstStartUp = false;
    },
    authRequestFailed: (state, action) => {
      state.isLoading = false;
      state.errorAuth = action.payload;
    },
    updateUserSuccess: (state, action) => {
      state.user = {
        ...state.user,
        ...action.payload
      };
    },
    settingsRequest: (state) => {
      state.isLoading = true;
      state.errorAuth = null;
    },
    settingsRequestSuccess: (state, action) => {
      state.isLoading = false;
      state.user = {
        ...state.user,
        ...action.payload
      };
    },
    settingsRequestFailed: (state, action) => {
      state.isLoading = false;
      state.errorAuth = action.payload;
    },
    checkinRequested: (state) => {
      state.error = null;
      state.isLoading = true;
    },
    checkinRequestSuccess: (state, action) => {
      state.isLoading = false;
      state.user = action.payload;
    },
    checkinRequestFailed: (state, action) => {
      state.error = action.payload;
      state.isLoading = false;
    },
    refferalsRequested: (state) => {
      state.error = null;
      state.isLoading = true;
    },
    refferalsRequestSuccess: (state, action) => {
      state.refferals = action.payload;
      state.isLoading = false;
      state.dataRefferals = true;
    },
    refferalsRequestFailed: (state, action) => {
      state.error = action.payload;
      state.isLoading = false;
    },
    syncEnergy: (state, action) => {
      state.lastCurrentEnergy = action.payload;
    },
    shutdownRequested: (state) => {
      state.error = null;
      state.isLoading = true;
    },
    shutdownRequestSuccess: (state) => {
      state.isLoading = false;
    },
    shutdownRequestFailed: (state) => {
      state.isLoading = false;
    },
    userInfoRequest: (state) => {
      state.isLoading = true;
      state.errorAuth = null;
    },
    userInfoRequestSuccess: (state, action) => {
      state.isLoading = false;
      state.user = action.payload;
    },
    userInfoRequestFailed: (state, action) => {
      state.isLoading = false;
      state.errorAuth = action.payload;
    },
  }
});

const { actions, reducer: userReducer } = userSlice;
const {
  authRequested,
  authRequestSuccess,
  authRequestFailed,
  updateUserSuccess,
  settingsRequest,
  settingsRequestSuccess,
  settingsRequestFailed,
  checkinRequested,
  checkinRequestSuccess,
  checkinRequestFailed,
  refferalsRequested,
  refferalsRequestSuccess,
  refferalsRequestFailed,
  syncEnergy,
  shutdownRequested,
  shutdownRequestSuccess,
  shutdownRequestFailed,
  userInfoRequest,
  userInfoRequestSuccess,
  userInfoRequestFailed,
} = actions;


export const login = ( payload: string ) => async (dispatch: any) => {
  const data = {
    initData: payload
  }
  dispatch(authRequested());
  try {
    const { content } = await authService.register(data);
    dispatch(authRequestSuccess(content));
    localStorageService.setTokens({
      accessToken: content.token,
      refreshToken: "refresh",
      expireAccess: 1500,
      expireRefresh: 60000
    });
    return content;
  } catch (error: any) {
      dispatch(authRequestFailed(error.message));
  }
};
export const checkIn = () => async (dispatch: any) => {
  dispatch(checkinRequested());
  try {
    const { content } = await gameService.checkIn();
    
    dispatch(checkinRequestSuccess(content.user));
  } catch (error: any) {
    dispatch(checkinRequestFailed(error.message));
  }
};
export const changeSettingsUser = ( payload: Settings ) => async (dispatch: any) => {
  dispatch(settingsRequest());
  try {
    await gameService.changeSettings(payload);
    dispatch(settingsRequestSuccess(payload));
  } catch (error: any) {
    dispatch(settingsRequestFailed(error.message));
  }
};
export const loadRefferals = ( ) => async (dispatch: any) => {
  dispatch(refferalsRequested());
  try {
    const { content } = await gameService.fetchRefferals();
    dispatch(refferalsRequestSuccess(content.userReferrals));
  } catch (error: any) {
    dispatch(refferalsRequestFailed(error.message));
  }
};
export const shutDown = ( ) => async (dispatch: any) => {
  dispatch(shutdownRequested());
  try {
    await gameService.shutdown();
    dispatch(shutdownRequestSuccess());
  } catch (error: any) {
    dispatch(shutdownRequestFailed(error.message));
  }
};
export const updateUserData = ( payload: object ) => (dispatch: any) => {
  dispatch(updateUserSuccess(payload));
};
export const syncEnergyUser = ( payload: SyncEnergy ) => (dispatch: any) => {
  dispatch(syncEnergy(payload));
};
export const userInfo = () => async (dispatch: any) => {
  dispatch(userInfoRequest());
  try {
    const { content } = await gameService.userInfo();

    dispatch(userInfoRequestSuccess(content.user));
  } catch (error: any) {
    dispatch(userInfoRequestFailed(error.message));
  }
};


export const getUserLoadingStatus = () => (state: RootState) => state.user.isLoading;
export const getUserDataStatus = () => (state: RootState) => state.user.dataLoaded;
export const getIsLoggedIn = () => (state: RootState) => state.user.isLoggedIn;
export const getUserData = () => (state: RootState) => state.user.user;
export const getCurrentUserToken = () => (state: RootState) => state.user.token;
export const getAuthErrors = () => (state: RootState) => state.user.errorAuth;
export const getUserLanguages = () => (state: RootState) => state.user.languages;
export const getUserFirstStartUp = () => (state: RootState) => state.user.firstStartUp;
export const getLanguageByLocale = (locale: string) => (state: RootState) => state.user.languages.find((lang: Language)=> lang.locale === locale);
export const getUserRefferalsData = () => (state: RootState) => state.user.refferals;
export const getUserRefferalsDataStatus = () => (state: RootState) => state.user.dataRefferals;
export const getUserLastEnergyAmount = () => (state: RootState) => state.user.lastCurrentEnergy;
export const getUserEarned = () => (state: RootState) => state.user.earned;
export const getUserRenewed = () => (state: RootState) => state.user.renewed;

export default userReducer;
