import ReactDOM from 'react-dom/client'
import App from './app/App'
import './index.css'
import { Provider } from 'react-redux'
import { BrowserRouter, Router } from 'react-router-dom'
import history from './app/utils/history'
import { store } from './app/store'
import './i18n';
import {setUpInterceptor} from "./app/services/http.service";

setUpInterceptor(store);

ReactDOM.createRoot(document.getElementById('root')!).render(
  <Provider store={store}>
    <Router history={history}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </Router>
  </Provider>
)
