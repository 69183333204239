import httpService from "./http.service";

const taskEndpoint = "tasks";
const checkTaskEndpoint = "tasks/check";

const taskService = {
  fetchAll: async () => {
    const { data } = await httpService.get(taskEndpoint);
    return data;
  },
  check: async (payload: string) => {
    const { data } = await httpService.get(checkTaskEndpoint, {
      params: {
        task_id: payload
      }
    });
    return data;
  },
};

export default taskService;
