import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "./createStore";
import taskService from "../services/task.service";
import { Task } from "../interfaces/task.type";
import {updateUserData} from "./user";

type SliceState = {
  entities: null | Task[],
  lastCheckTask: any,
  isLoading: boolean,
  error: any,
  dataLoaded: boolean
}
const initialState: SliceState = {
  entities: [],
  isLoading: false,
  lastCheckTask: null,
  error: null,
  dataLoaded: false
};

const tasksSlice = createSlice({
  name: "tasks",
  initialState,
  reducers: {
    tasksRequested: (state) => {
      state.isLoading = true;
      state.error = null;
    },
    tasksRequestSuccess: (state, action) => {
      state.entities = action.payload;
      state.isLoading = false;
      state.dataLoaded = true;
    },
    tasksRequestFailed: (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    },
    checkTaskRequested: (state) => {
      state.isLoading = true;
      state.error = null;
    },
    checkTaskRequestSuccess: (state, action) => {
      state.lastCheckTask = action.payload;
      state.isLoading = false;
    },
    checkTaskRequestFailed: (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    },
    updateTaskStatus: (state, action) => {
      state.entities = state.entities && state.entities.map(item => {
        if (action.payload.id && action.payload.status && item.id === action.payload.id) {
          return {
            ...item,
            user_task_status: action.payload.status
          }
        }
        return item;
      })
    }
  }
});

const { actions, reducer: tasksReducer } = tasksSlice;
const {
  tasksRequested,
  tasksRequestSuccess,
  tasksRequestFailed,
  checkTaskRequested,
  checkTaskRequestSuccess,
  checkTaskRequestFailed,
  updateTaskStatus
} = actions;


export const loadTasks = () => async (dispatch: any) => {
  dispatch(tasksRequested());
  try {
    const { content } = await taskService.fetchAll();
    dispatch(tasksRequestSuccess(content.tasks));
  } catch (error: any) {
      dispatch(tasksRequestFailed(error.message));
  }
};
export const checkedTask = (payload: string) => async (dispatch: any) => {
  dispatch(checkTaskRequested());
  try {
    const { content } = await taskService.check(payload);
    dispatch(checkTaskRequestSuccess(content));
    dispatch(updateTaskStatus({status: content.user_task_status, id: content.task.id}))
    dispatch(updateUserData(content.user))
  } catch (error: any) {
    dispatch(checkTaskRequestFailed(error.message));
  }
};


export const getTasksLoadingStatus = () => (state: RootState) => state.tasks.isLoading;
export const getTasksDataStatus = () => (state: RootState) => state.tasks.dataLoaded;
export const getTasksData = () => (state: RootState) => state.tasks.entities;
export const getTasksErrors = () => (state: RootState) => state.tasks.error;

export default tasksReducer;
