import styles from "../../MainPage.module.css";
import React, {FC, useEffect, useRef} from "react";
import getRandomAnimation from "../../../../../utils/animations";
import {SpinePlayer} from "@esotericsoftware/spine-player";
import {useAppDispatch, useAppSelector} from "../../../../../hooks/hooks";
import {getUserData, updateUserData} from "../../../../../store/user";
import {IUser} from "../../../../../interfaces/user.type";
import {syncTapWithServer} from "../../../../../store/game";
import {createTapElement} from "../../utils";

type Props = {
  energy: number;
  setEnergy:  React.Dispatch<React.SetStateAction<number>>;
  setOnTimer: React.Dispatch<React.SetStateAction<boolean>>;
  currentTapPerSecond: number;
  setCurrentTapPerSecond: React.Dispatch<React.SetStateAction<number>>;
  powerTap: number;
  maxTapPerSecond: number;
  exp: React.MutableRefObject<number>
  maxEnergy: number;
  onTimer: boolean;
}

const atlasUrl = './assets/animation/Fighter/Fighter.atlas';
const jsonUrl = './assets/animation/Fighter/Fighter.json';

const MAX_MULTITAP_COUNT = 5;

export const PunchingBagSection:FC<Props> = ({
  energy,
  setEnergy,
  setOnTimer,
  maxTapPerSecond,
  currentTapPerSecond,
  setCurrentTapPerSecond,
  powerTap,
  maxEnergy,
  onTimer,
  exp,
}) => {
  const initUser = useAppSelector(getUserData()) as IUser;
  const punchigBagPlayer = useRef<SpinePlayer | null>(null);
  const punchigBagPlayerIsReady = useRef<boolean>(false);
  const countTapRef = useRef<number>(0);
  const tapTimeoutRef = useRef<number | null>(null);
  const tg: WebApp = window.Telegram.WebApp;
  const dispatch = useAppDispatch();

  useEffect(() => {
    punchigBagPlayer.current = new SpinePlayer("punching-bag", {
      jsonUrl: jsonUrl,
      atlasUrl: atlasUrl,
      premultipliedAlpha: true,
      preserveDrawingBuffer: false,
      showControls: false,
      defaultMix: 0,
      showLoading: false,
      animations: ["Idle", "Hit1"],
      skin: "Test_01",
      alpha: true,
      backgroundColor: "#00000000",
      viewport: {
        x: 0,
        y: 0,
        padRight: 0,
        padTop: 100,
        padBottom: 350,
        padLeft: 0
      },
      scale: 2,
      success: () => {
        punchigBagPlayerIsReady.current = true;
      }
    });
  }, []);

  const syncAnimation = () => {
    if (!punchigBagPlayer.current || !punchigBagPlayerIsReady.current) {
      return
    }

    punchigBagPlayer.current?.setAnimation("Idle", true);
  };

  const syncServer = () => {
    const taps = countTapRef.current;
    const timestamp = Math.floor(Date.now() / 1000);
    const usedEnergy = Number(countTapRef.current * Number(powerTap));
    const tapEnergy = Number(energy) <= 0 ? 0 : Number(energy);

    dispatch(syncTapWithServer({
      taps: taps,
      timestamp: timestamp,
      usedEnergy: usedEnergy,
      tap_energy: tapEnergy,
    }));
    dispatch(updateUserData({
      experience: Number(exp.current)
    }));
    countTapRef.current = 0;
  };

  useEffect(() => {
    if (onTimer) {
      if (countTapRef.current > 0) {
        tapTimeoutRef.current = setTimeout(syncServer, 500);
      }

      const animationTimeout = setTimeout(syncAnimation, 1000);

      return () => {
        if (tapTimeoutRef.current != null) {
          clearTimeout(tapTimeoutRef.current);
        }
        clearTimeout(animationTimeout);
      };
    }
  }, [onTimer]);

  useEffect(() => {
    return () => {
      if (countTapRef.current > 0) {
        syncServer()
      }
    }
  }, [])

  const handleTouchStart = (e: React.TouchEvent) => {
    e.stopPropagation();

    if (!punchigBagPlayer.current || !energy || !punchigBagPlayerIsReady.current) {
      return
    }

    setOnTimer(false);

    if (currentTapPerSecond < maxTapPerSecond) {

      const touches = e.touches.length >= MAX_MULTITAP_COUNT ? MAX_MULTITAP_COUNT : e.touches.length;

      setCurrentTapPerSecond((prev: number) => {
        if((Number(energy) - Number(powerTap) * touches) < 0 ) return prev;
        return prev + touches;
      });

      if (!((Number(energy) - Number(powerTap) * touches) < 0)) {
        if (initUser.user_setting.vibration) {
          tg.HapticFeedback.impactOccurred("soft");
        }

        punchigBagPlayer.current?.setAnimation(getRandomAnimation(['Hit1', 'Hit2', 'Hit3', 'Hit4', 'Hit5', 'Hit6']), false);
        countTapRef.current = countTapRef.current + touches;

        for (let i = 0; i < touches; i++) {
          const touch = e.touches[i];
          createTapElement({
            touch,
            powerTap,
            type: 'punching-bag',
            containerId: 'punching-bag'
          });
        }
      }

      if (!((Number(energy) - Number(powerTap) * touches) < 0 )) {
        exp.current = Number(exp.current) + Number(powerTap) * touches
      }

      setEnergy((prev: number) => {
        if (Number(prev) >= Number(powerTap) * touches) {
          return Number(prev ?? maxEnergy) - Number(powerTap) * touches;
        }
        return prev;
      });
    }
  };

  const handleTouchEnd = (e: React.TouchEvent) => {
    e.stopPropagation();
    setOnTimer(true);
  };

  return (
    <div
      className={styles.Player}
      onTouchStart={handleTouchStart}
      onTouchEnd={handleTouchEnd}
      id="punching-bag"
      style={{ width: '100%' }}
    />
  )
}