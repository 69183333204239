import {CSSProperties, useEffect} from 'react';
import styles from './FriendsContent.module.css';
import TableRowFriends from '../TableRowFriends';
import {useAppDispatch, useAppSelector} from '../../../hooks/hooks';
import {getUserRefferalsData, loadRefferals} from '../../../store/user';
import { IRefferal } from '../../../interfaces/refferal.type';
import { useTranslation } from 'react-i18next';

type FriendsContentProps = {
  style?: CSSProperties
};

export default function FriendsContent({ style }:FriendsContentProps) {
  const dispatch = useAppDispatch();
  const refferals = useAppSelector(getUserRefferalsData());
  const {t} = useTranslation('ns1');

  useEffect(() => {
    dispatch(loadRefferals())
  }, [])

  return (
    <div className={styles.FriendsContent} style={style}>
      {
        refferals && refferals?.length ? (
          <div className={styles.FriendsCount}>{t('You have friends', {count: refferals.length})}</div>
        ) : null
      }
      <div className={styles.Table}>
        <div className={styles.Header}>
          <div className={styles.Column}>
            {t('List of your friends')}
          </div>
          <div className={styles.Column}>
            {t('Your reward')}
          </div>
        </div>
        <div className={styles.Body}>
          {refferals && refferals?.length !== 0 ? refferals.map((ref: IRefferal, index:number) => (
            <TableRowFriends
              key={ref.id}
              referral={ref}
              last={index === refferals.length - 1}
            />
          )) : ""}
        </div>
      </div>
    </div>
  )
}
