import React, {useEffect, useState} from "react";
import i18next from 'i18next';
import { useAppDispatch, useAppSelector } from "../../hooks/hooks";
import {getUserData, getUserDataStatus, login, shutDown} from "../../store/user";
import {useHistory} from "react-router-dom";
import {ROUTES} from "../../routes";
import {ToastContainer} from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import Notification from "../ui/Notification";

type AppLoaderProps = {
  children: React.ReactNode
}

const AppLoader = ({ children }: AppLoaderProps) => {
  const tg: WebApp = window.Telegram.WebApp;
  const dispatch = useAppDispatch();
  const userDataStatus = useAppSelector(getUserDataStatus());
  const history = useHistory();
  const [ show, setShow ] = useState<boolean>(false);
  const locale = useAppSelector(getUserData())?.user_setting.locale;

  useEffect(() => {
    if (locale) i18next.changeLanguage(locale);
  }, [locale]);

  useEffect(() => {
    const interval = setInterval(() => {
      const initialLoaderElem = document.getElementById('initial-loader');

      if (initialLoaderElem?.dataset?.loading === 'false' && userDataStatus) {
        // удаляем всё что связано с начальным лоадером
        document.getElementById('initial-loader__loading-value')!.style.width = '100%';
        document.getElementById('initial-loader')!.remove();

        clearInterval(interval);
      }
    }, 1000);

    return () => {
      clearInterval(interval);
    }
  }, [userDataStatus])

  useEffect(() => {
    if (!userDataStatus) {
      dispatch(login(tg.initData))
        .then((userData: any) => {
          if (!userData?.user?.user_setting?.is_onboarding_complete) {
            history.push({
              pathname: ROUTES.onboarding.root,
              state: {
                isOnboarding: true
              }
            })
          }
          if (userData?.earned?.experience > 0) {
            setShow(true);
          }
        })
    }
  }, [userDataStatus, dispatch]);

  useEffect(() => {
    return () => {
      dispatch(shutDown());
    }
  }, []);

  return <>
    {
      userDataStatus && (
        <>
          {children}
          <Notification open={show} onClose={setShow} />
        </>
      )
    }
    <ToastContainer />
  </>;
};

export default AppLoader;
