import {LeagueIdType} from "../interfaces/rating.type";

function getPathAndAlt(namePicture: string | undefined) {
  switch (namePicture) {
    case "TON":
      return { src: "assets/icons/TON.svg", alt: "picture TON" };
    case "XTR":
      return { src: "assets/icons/XTR.svg", alt: "picture XTR" };
    case "USDT":
      return { src: "assets/icons/USDT.svg", alt: "picture USDT" };
    case "silver":
      return { src: "assets/icons/Silver.svg", alt: "picture Silver" };
    case "stack":
      return { src: "assets/icons/Stack.svg", alt: "picture Stack" };
    case "energy":
      return { src: "assets/icons/Energy.svg", alt: "picture energy" };
    case "karate":
      return { src: "assets/icons/Karate.svg", alt: "picture Karate" };
    case "knockout":
      return { src: "assets/icons/Knockout.svg", alt: "picture Knockout" };
    case "shop":
      return { src: "assets/images/basket.webp", alt: "picture shop" };
    case "star":
      return { src: "assets/icons/Star.svg", alt: "picture star" };
    case "gift":
      return { src: "assets/icons/Gift.svg", alt: "picture gift" };
    case "pro":
      return { src: "assets/icons/Pro.svg", alt: "picture pro" };
    case "sparring":
      return { src: "assets/images/Sparring.webp", alt: "picture sparring" };
    case "combo":
      return { src: "assets/images/Daily-combo.webp", alt: "picture Combo" };
    case "telegram":
      return { src: "assets/icons/Telegram.svg", alt: "picture telegram" };
    case "youtube":
      return { src: "assets/icons/Youtube.svg", alt: "picture youtube" };
    case "calendar":
      return { src: "assets/images/reward.webp", alt: "picture calendar of experience" };
    case "exp":
      return { src: "assets/icons/XP.svg", alt: "picture medal of experience" };
    case "twitter": {
      return { src: "assets/icons/Twitter.svg", alt: "picture twitter"};
    }
    default:
      return { src: "assets/icons/Empty.svg", alt: "picture empty" };
  }
}

const leagueAssetsByLeagueIdMapper: Record<LeagueIdType, {
  flagUrl: string,
  fighterUrl: string
}> = {
  "junior_3": {
    flagUrl: 'https://assets.mmator.online/leagues/flags/junior_3.webp',
    fighterUrl: 'https://assets.mmator.online/leagues/fighters/junior.webp',
  },
  "junior_2": {
    flagUrl: 'https://assets.mmator.online/leagues/flags/junior_2.webp',
    fighterUrl: 'https://assets.mmator.online/leagues/fighters/junior.webp',
  },
  "junior_1": {
    flagUrl: 'https://assets.mmator.online/leagues/flags/junior_1.webp',
    fighterUrl: 'https://assets.mmator.online/leagues/fighters/junior.webp',
  },
  "adult_3": {
    flagUrl: 'https://assets.mmator.online/leagues/flags/sport_3.webp',
    fighterUrl: 'https://assets.mmator.online/leagues/fighters/sport.webp',
  },
  "adult_2": {
    flagUrl: 'https://assets.mmator.online/leagues/flags/sport_2.webp',
    fighterUrl: 'https://assets.mmator.online/leagues/fighters/sport.webp',
  },
  "adult_1": {
    flagUrl: 'https://assets.mmator.online/leagues/flags/sport_1.webp',
    fighterUrl: 'https://assets.mmator.online/leagues/fighters/sport.webp',
  },
  "cms": {
    flagUrl: 'https://assets.mmator.online/leagues/flags/cms.webp',
    fighterUrl: 'https://assets.mmator.online/leagues/fighters/ms.webp',
  },
  "ms": {
    flagUrl: 'https://assets.mmator.online/leagues/flags/ms.webp',
    fighterUrl: 'https://assets.mmator.online/leagues/fighters/ms.webp',
  },
  "msic": {
    flagUrl: 'https://assets.mmator.online/leagues/flags/msic.webp',
    fighterUrl: 'https://assets.mmator.online/leagues/fighters/ms.webp',
  },
  "professional": {
    flagUrl: 'https://assets.mmator.online/leagues/flags/professional.webp',
    fighterUrl: 'https://assets.mmator.online/leagues/fighters/pro.webp',
  },
}

export const getLeagueAssetsByLeagueId = (leagueId: LeagueIdType | undefined | null) => {
  if (leagueId == null) {
    return leagueAssetsByLeagueIdMapper.junior_3;
  }

  return leagueAssetsByLeagueIdMapper[leagueId] || leagueAssetsByLeagueIdMapper.junior_3;
}

export default getPathAndAlt;